<template>
  <div class="sub-filter" v-if="filterData && !filterLoading">
    <div class="item">
      <div class="head">分类：</div>
      <div class="body">
        <a class="ellipsis" :title="item.name" @click="changeBrand(item.categoryId)"
          :class="{ active: item.categoryId === filterData.selectedBrand }" href="javascript:;" v-for="item in filterData"
          :key="item.categoryId">{{ item.name }}</a>
      </div>
    </div>
    <div class="item">
      <div class="head">类型：</div>
      <div class="body">
        <a class="ellipsis" :title="prop.name" @click="changeProp(prop.categoryId)"
          :class="{ active: prop.categoryId === subList.selectedProp }" href="javascript:;" v-for="prop in subList"
          :key="prop.categoryId">{{ prop.name }}</a>
      </div>
    </div>
  </div>
  <div v-else class="sub-filter">
    <BaseSkeleton class="item" width="800px" height="40px" />
    <BaseSkeleton class="item" width="800px" height="40px" />
    <BaseSkeleton class="item" width="600px" height="40px" />
    <BaseSkeleton class="item" width="600px" height="40px" />
    <BaseSkeleton class="item" width="600px" height="40px" />
  </div>
</template>
<script>
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
// import { findSubCategoryFilter } from '@/api/category'
import { findProductCategoryFilter } from '@/api/category'
import BaseSkeleton from "@/components/library/base-skeleton.vue";

export default {
  name: 'SubFilter',
  components: {BaseSkeleton},
  setup(props, { emit }) {
    const route = useRoute()
    // 监听二级类目ID的变化获取筛选数据
    const filterData = ref(null)
    const subList = ref(null)
    const subListDeaulf = ref(null)
    const filterLoading = ref(false)

    watch(() => route.params.id, (newVal) => {
      // 变化后的ID有值 且 处在二级类名路由下

        filterLoading.value = true
        // 发请求获取数据
        findProductCategoryFilter(route.params.id).then(data => {
          // 每一组可选的筛选条件缺失 全部 条件，处理下数据加上全部
          // 给每一组数据加上一个选中的ID
          // 1. 品牌
          //data.data.list.selectedBrand = null
          // data.data.list.brands.unshift({ id: null, name: '全部' })
          data.data.list.unshift({ id: null, name: '全部' })
          // 2. 属性
          // data.data.list.saleProperties.forEach(item => {
          //   item.selectedProp = null
          //   item.properties.unshift({ id: null, name: '全部' })
          // })
          // 设置修改的数据

          filterData.value = data.data.list
          subList.value = [
            { id: null, name: '全部' },
            ...data.data.list.map(item => {
              if (item.children) {
                return item.children;
              }
            }).filter(Boolean).flat()
          ];
          subListDeaulf.value = subList.value
          //获得所有的数据
          filterLoading.value = false
        })

    }, { immediate: true })


    // 获取筛选参数的函数
    const getFilterParams = (categoryId) => {
      // const obj = { brandId: null, attrs: [] }
      // // 品牌
      // alert(filterData.value.selectedBrand)
      // obj.brandId = filterData.value.selectedBrand
      const obj = { brandId: null, attrs: [] }
      // 品牌
      obj.brandId = categoryId
      // // 销售属性
      // filterData.value.saleProperties.forEach(item => {
      //   if (item.selectedProp) {
      //     const prop = item.properties.find(prop => prop.id === item.selectedProp)
      //     obj.attrs.push({ groupName: item.name, propertyName: prop.name })
      //   }
      // })
      // 参考数据：{brandId:'',attrs:[{groupName:'',propertyName:''},...]}
      if (obj.attrs.length === 0) obj.attrs = null
      return obj
    }

    // 1. 记录当前选择的品牌
    const changeBrand = (brandId) => {
      if (filterData.value.selectedBrand === brandId) return
      filterData.value.selectedBrand = brandId

      if (brandId === undefined) {
        subList.value = subListDeaulf.value
       }else{
        // 获得子菜单值，赋值给subList
        const childrenArray = filterData.value.filter(item => item.categoryId === brandId);
        subList.value = [
          { id: null, name: '全部' },
          ...(childrenArray.map(item => item.children) // 将 children 收集成数组
            .filter(Boolean) // 过滤掉没有 children 的元素
            .flat())
        ];
        // 判断如果没有 children，仅保留 { id: null, name: '全部' }
        if (subList.value.length === 1) {
          subList.value = [{ id: null, name: '全部' }];
        }
      }
      emit('filter-change', getFilterParams(brandId))
    }

    // 2. 记录呢选择的销售属性
    const changeProp = (propId) => {
      //active: prop.categoryId === subList.selectedProp
      // if (subList.value.selectedProp === propId) {
      //   return subList.value.selectedProp = propId
      // }
      subList.value.selectedProp = propId
      emit('filter-change', getFilterParams(propId))
    }

    return { filterData, filterLoading, changeBrand, changeProp, subList, subListDeaulf }
  }
}
</script>
<style scoped lang='less'>
// 筛选区
.sub-filter {
  background: #fff;
  padding: 25px;

  .item {
    display: flex;

    .head {
      width: 80px;
      color: #999;
      line-height: 40px;
    }

    .body {
      flex: 1;

      a {
        margin-right: 36px;
        transition: all .3s;
        display: inline-block;
        max-width: 112px;
        line-height: 40px;

        &.active,
        &:hover {
          color: red;
        }
      }
    }
  }

  .base-skeleton {
    padding: 10px 0;
  }
}</style>
